<template>
  <BaseDetailSimple
    :input-attributes="inputAttributes"
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="language"
    locale-section="pages.languages"
    field-section="languages"
  />
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";

export default {
  name: "LanguageDetailPage",
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        _name: language
        language
        sv
        en
      `,
      inputAttributes: {
        language: { required: true },
        sv: { required: true },
        en: { required: true }
      }
    };
  }
};
</script>
